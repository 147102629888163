<template>
  <div class="warp">
    <!-- 大盒子背景图 type == 2 注册-->
    <!-- 小盒子定位 注册第一步 -->
    <div class="boxb" v-if="type == 2">
      <div class="boxbs">
        <!-- 缩宽 -->
        <div class="boxbss">
          <!-- 标题 -->
          <div class="boxbsName">注册聚学云网校账号</div>
          <el-form :model="ruleForm" :rules="rules" ref="registerFrom">
            <el-form-item prop="username">
              <el-input placeholder="填写昵称" prefix-icon="el-icon-user" v-model.trim="ruleForm.username" />
            </el-form-item>

            <el-form-item prop="phone">
              <el-input placeholder="填写手机号" prefix-icon="el-icon-mobile-phone" v-model.trim="ruleForm.phone" />
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="设置密码，长度为6~20个字母、数字、符号" prefix-icon="el-icon-lock" v-model.trim="ruleForm.password"
                show-password />
            </el-form-item>
            <el-form-item prop="passwords">
              <el-input placeholder="再次确认密码" prefix-icon="el-icon-lock" v-model.trim="ruleForm.passwords" show-password />
            </el-form-item>
            <el-form-item prop="imgCode">
              <div class="dis_flex_between">
                <el-input placeholder="验证码" prefix-icon="el-icon-c-scale-to-original" v-model.trim="ruleForm.imgCode"
                  style="width: 60%" />
                <div class="diva">
                  <img :src="captcha" alt="验证码" @click="refresh" />
                </div>
              </div>
            </el-form-item>
          </el-form>
          <!-- 注册按钮 -->
          <div class="boxbsBtn" @click="register">立即注册</div>
          <!-- 底部必选已同意 -->
          <div class="boxbsBtm">
            <input type="checkbox" v-model.trim="checkboxs" />
            <p>
              我已阅读并同意&nbsp;<span class="pointer" @click="goNext(`/agreement`)">《新里程网校服务协议》</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 小盒子定位 注册第二步-->
    <div class="boxb" v-if="type == 20">
      <div class="boxbs">
        <!-- 缩宽 -->
        <div class="boxbss">
          <!-- 标题 -->
          <div class="boxbsName">注册聚学云网校账号</div>
          <!-- 标题下图片 -->
          <div class="boxbssHd">
            <img class="imga" src="@/assets/img/Login/loginIcon6.png" />
            <img class="imgb" src="@/assets/img/Login/loginIcon682.png" />
            <img class="imgc" src="@/assets/img/Login/loginIcon7.png" />
          </div>
          <p class="boxbssHdp">
            请注意查看验证码已发送至<span>{{ ruleForm.phone }}</span>
          </p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" onsubmit="return false;"
            style="display: flex; justify-content: space-between; align-items: flex-start; margin-top: 21px">
            <el-form-item prop="phoneCode" style="width: 210px">
              <el-input placeholder="验证码" prefix-icon="el-icon-c-scale-to-original" v-model.trim="ruleForm.phoneCode"
                @keyup.enter.native="registers" />
            </el-form-item>
            <button type="button" class="boxbssHdBtn" :class="time > 0 ? 'notClick' : ''" @click="getPhoneCode"
              :disable="disable">
              {{ titles }}
            </button>
          </el-form>
          <!-- 注册按钮 -->
          <div class="boxbsBtn" @click="registers">立即注册</div>
          <!-- 底部必选已同意 -->
          <div class="boxbsBtm">
            <input type="checkbox" v-model.trim="checkboxs" />
            <p>
              我已阅读并同意&nbsp;<span class="pointer" @click="goNext(`/agreement`)">《新里程网校服务协议》</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { imagecode, addPcUser, selectRegisterMobile, selectCode } from '@/api/register'
import { loginPcUser, loginSmsCode, loginPcUserSms } from '@/api/login'
export default {
  components: {},
  data() {
    // 验证手机号唯一性
    let phoneProp = (rule, value, callback) => {
      selectRegisterMobile(value).then((res) => {
        if (res.msg) {
          callback('当前手机号已存在')
        } else {
          callback()
        }
      })
    }
    let codeProp = (rule, value, callback) => {
      selectCode(value).then((res) => {
        if (res.code == 0) {
          callback()
        } else {
          callback('请输入正确的验证码')
        }
      })
    }
    let passwordsProp = (rule, value, callback) => {
      if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      errorMsg1: '',
      tenantId: '',
      type: 2,
      disable: false,
      titles: '获取验证码',
      time: 0,
      input: '',
      checkboxs: false,
      welcomePotential: 'confirmToken',
      ruleForm: {
        username: '',
        password: '',
        imgCode: '',
        phone: '',
        phoneCode: '',
      },
      ruleFormb: { welcomePotential: 'welcomePotential' },
      captcha: '',
      rules: {
        username: [{ required: true, message: '填写昵称不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur',
          },
          /*  {
            validator: phoneProp,
            trigger: "blur",
          }, */
        ],
        phones: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur',
          },
        ],
        zhphone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur',
          },
        ],
        account: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
          { min: 6, message: '账号不能长度小于6个字符', trigger: 'blur' },
          { max: 10, message: '账号不能长度大于10个字符', trigger: 'blur' },
        ],
        zhpassword: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码不能长度小于6个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码不能长度小于6个字符', trigger: 'blur' },
        ],
        passwords: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码不能长度小于6个字符', trigger: 'blur' },
          {
            validator: passwordsProp,
            trigger: 'blur',
          },
        ],
        imgCode: [
          { required: true, message: '图形验证码不能为空', trigger: 'blur' },
          {
            min: 4,
            max: 4,
            message: '请输入正确的图形验证码',
            trigger: 'blur',
          },
          {
            validator: codeProp,
            trigger: 'blur',
          },
        ],
        phoneCode: [{ required: true, message: '手机验证码不能为空', trigger: 'blur' }],
        shortCode: [{ required: true, message: '手机验证码不能为空', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.loadCaptcha()
  },
  methods: {
    // 注册验证码
    getPhoneCode() {
      if (this.time > 0) {
        return
      }
      if (!/^[1]([3-9])[0-9]{9}$/.test(this.ruleForm.phone)) {
        this.$message.error('手机号格式不正确')
        return
      }
      loginSmsCode(this.ruleForm.phone, 'confirmToken').then((res) => {
        if (res.code == 0) {
          this.time = 60
          this.titles = this.time-- + '秒后重发'
          var wws = setInterval(() => {
            if (this.time < 1) {
              this.titles = '获取验证码'
              this.disable = false
              clearInterval(wws)
            } else {
              this.titles = this.time-- + '秒后重发'
              this.disable = true
            }
          }, 1000)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // ...mapActions(['loginPcUser']),
    // 刷新图形验证码验证码
    refresh() {
      this.ruleFormb.imgCode = ''
      this.ruleForm.imgCode = ''
      this.loadCaptcha()
    },
    // 获取图形验证码
    loadCaptcha() {
      imagecode().then((res) => {
        this.captcha = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
        )}`

        // this.captcha = res
      })
    },
    // 注册 第一步
    register() {
      this.$refs.registerFrom.validate(async (valid) => {
        if (valid) {
          if (this.checkboxs !== true) {
            this.$message.error('请勾选我已阅读并同意《新里程网校服务协议》')
          } else {
            this.type = 20
          }
        }
      })
    },
    // 注册 第二步
    registers() {
      if (this.checkboxs !== true) {
        this.$message.error('请勾选我已阅读并同意《新里程网校服务协议》')
      } else {
        if (this.ruleForm.phoneCode != '') {
          addPcUser(
            this.ruleForm.username,
            this.ruleForm.password,
            this.ruleForm.phone,
            this.ruleForm.phoneCode,
            this.ruleForm.account
          ).then((res) => {
            if (res.code == 0) {
              this.$message.success('注册成功')
              this.$router.push('/login')
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error('请输入手机验证码')
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.warp {
  width: 100%;

  // height: 1080px;
  // 导航栏顶部灰条
  .header {
    width: 100%;
    height: 36px;
    background: #f5f7fa;

    // 宽度缩放60%
    .headers {
      width: 65%;
      height: 100%;
      margin: auto;
      // border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // 左侧
      ul {
        width: auto;
        height: 100%;

        li {
          float: left;
          line-height: 36px;
          margin-right: 30px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
      }

      // 右侧
      .headersRight {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }
  }

  // 大盒子背景图 登录 type=1
  .box {
    width: 100%;
    height: 670px;
    background-image: url('../../assets/img/Login/loginIcon68.png');
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 40px;

    // 小盒子定位
    .boxs {
      position: absolute;
      top: 70px;
      right: 360px;
      width: 380px;
      height: 482px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(222, 224, 228, 0.9);
      border-radius: 10px;

      // 微信登录
      .weiXin {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        // 微信登录左
        .weiXinLeft {
          width: 67px;
          height: 22px;
          background-image: url('../../assets/img/Login/yuanjiao.png');
          background-size: 100% 100%;
          margin: 0px 3px 4px 0px;
          text-align: center;
          line-height: 22px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f15817;
          cursor: pointer;
        }

        // 微信登录右验证码
        .weiXinRight {
          width: 34px;
          height: 34px;
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      // 密码、短信登录选择
      .choice {
        width: 65%;
        height: auto;
        margin: auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        // 密码登录
        .choiceLeft {
          width: auto;
          height: 35px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .choiceLeftText {
            width: auto;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #7b8196;
            cursor: pointer;
          }

          .class1 {
            color: #27323f;
            cursor: pointer;
          }

          .choiceLefts {
            margin: auto;
            margin-top: 8px;
            width: 30px;
            height: 3px;
            background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
            border-radius: 2px;
          }
        }

        // 分割线
        .choices {
          width: 1px;
          height: 16px;
          background: #dddddd;
          margin-bottom: 7px;
        }
      }

      // 密码、短信登录选择内容
      .content {
        width: 100%;
        height: 350px;
        margin-top: 30px;

        .el-form-item {
          margin-bottom: 32px;
        }

        .contentTop {
          width: 80%;
          height: 263px;
          margin: auto;

          // 输入框
          .el-input {
            width: 100%;
            height: 46px;
            // margin-bottom: 10px;
          }

          // 单选框
          .contentTops {
            width: 100%;
            height: 15px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            //   左盒子
            .contentTopsLeft {
              width: 80px;
              height: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              // 勾选自动登录输入框
              input {
                width: 12px;
                height: 100%;
                margin-right: 8px;
              }

              // 自动登录
              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #a8abbe;
                cursor: pointer;
              }
            }

            //   忘记密码
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a8abbe;
              cursor: pointer;
            }
          }

          // 登录按钮
          .contentTopBtn {
            width: 100%;
            height: 46px;
            margin-top: 16px;
            background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
            box-shadow: 0px 2px 8px 0px rgba(203, 25, 0, 0.28);
            border-radius: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }
        }

        //  底部 没有账号？点我注册
        .contentBtm {
          width: 100%;
          height: 70px;
          margin-top: 10px;
          background: #f8f8f8;
          border-radius: 0px 0px 10px 10px;
          text-align: center;
          line-height: 70px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7b8196;
          cursor: pointer;

          span {
            color: #ff4027;
          }
        }
      }
    }
  }

  // 大盒子背景图 注册 type=2
  .boxb {
    width: 100%;
    height: 670px;
    background-image: url('../../assets/img/Login/loginIcon68.png');
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 40px;

    // 小盒子定位
    .boxbs {
      position: absolute;
      top: 70px;
      right: 360px;
      width: 380px;
      height: 510px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(222, 224, 228, 0.9);
      border-radius: 10px;

      // 缩宽
      .boxbss {
        width: 80%;
        height: 100%;
        margin: auto;

        // 标题
        .boxbsName {
          width: 163px;
          height: 17px;
          margin: 30px auto;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
        }

        // 标题下图片
        .boxbssHd {
          width: 222px;
          height: 35px;
          margin: 0px auto;
          margin-top: 54px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .imga {
            width: 24px;
            height: 35px;
          }

          .imgb {
            width: 124px;
            height: 4px;
          }

          .imgc {
            width: 24px;
            height: 18px;
          }
        }

        .boxbssHdp {
          width: 100%;
          height: 12px;
          margin-top: 40px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a8abbe;

          span {
            color: #333333;
          }
        }

        .el-form {
          .boxbssHdBtn {
            width: 87px;
            height: 40px;
            outline: none;
            background: #eeeeee;
            border: none;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff4027;
            cursor: pointer;
          }
        }

        // 注册按钮
        .boxbsBtn {
          width: 320px;
          height: 46px;
          margin: 22px auto;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          box-shadow: 0px 2px 8px 0px rgba(203, 25, 0, 0.28);
          border-radius: 23px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }

        // 底部必选已同意
        .boxbsBtm {
          width: 100%;
          height: 13px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          input {
            margin: 0px 6px 0px 21px;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #7b8196;

            span {
              color: #ff4027;
            }
          }
        }
      }
    }
  }

  // 大盒子背景图 忘记密码 type=3
  .boxc {
    width: 100%;
    margin-bottom: 40px;
    height: 740px;
    background: #f3f4f7;
    overflow: hidden;

    // 内容框
    .boxcs {
      width: 1200px;
      height: 630px;
      margin: auto;
      margin-top: 40px;
      background: #ffffff;
      overflow: hidden;
      position: relative;

      // 标题
      .boxcsHead {
        width: 80px;
        height: 18px;
        margin: 30px 0px 0px 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;
      }

      // 虚线
      .line {
        width: 960px;
        height: 1px;
        margin: auto;
        margin-top: 84px;
        background: #e3e3e3;
      }

      // 内容
      .boxcsCent {
        width: 430px;
        height: 200px;
        margin: auto;
        margin-top: 122px;

        // 输入框盒子
        .boxcsCents {
          width: 100%;
          height: 46px;
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            width: auto;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }

          div {
            width: 96px;
            height: 40px;
            display: flex;
            margin-left: 14px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff7f48;
            cursor: pointer;
          }

          button {
            outline: none;
            border: none;
            width: 96px;
            height: 40px;
            display: flex;
            margin-left: 14px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff7f48;
            cursor: pointer;
          }
        }

        // 找回密码第四步确认图片盒子
        .zhQueren {
          width: 180px;
          height: 122px;
          margin: auto;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          p {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }
        }

        // 按钮
        .boxcsCentss {
          width: 320px;
          height: 46px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          border-radius: 2px;
          margin-left: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }

      // 底部
      .boxcsFoot {
        width: 1140px;
        height: 96px;
        margin: auto;
        margin-top: 61px;
        background: #fff4ef;
        border-radius: 4px;
        overflow: hidden;

        .boxcsFoota {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27323f;
          margin: 20px 0px 10px 20px;
        }

        .boxcsFootb {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7b8196;
          margin: 0px 0px 3px 20px;
        }
      }

      // 定位手机号
      .phone {
        position: absolute;
        top: 213px;
        left: 397px;
        z-index: 999999;
        width: 180px;
        height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;

        p {
          width: auto;
          height: auto;
        }

        span {
          width: auto;
          height: auto;
        }
      }

      // 定位图片1
      .boxcsImga {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        left: 164px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片2
      .boxcsImgb {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        left: 432px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片3
      .boxcsImgc {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        right: 432px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片4
      .boxcsImgd {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        right: 164px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .diva {
    width: 96px;
    height: 40px;
    border: 1px solid #dddddd;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 获取动态码
  .divb {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a8abbe;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    cursor: pointer;
  }

  .divb:hover {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #ff4027;
    color: #ff4027;
  }

  // 微信登录
  .weiXinName {
    width: 100%;
    height: 55px;
    text-align: center;
    line-height: 55px;
    font-size: 18px;
    margin-bottom: 17px;
  }

  // 扫描二维码
  .weiXinImg {
    width: 200px;
    height: 200px;
    border-radius: 2px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 二维码下方字体
  .weiXinNames {
    margin: auto;
    margin-top: 20px;
    text-align: center;
    width: 169px;
    height: 32px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
    line-height: 20px;
  }

  // 底部
  .weiXinFoot {
    margin: auto;
    margin-top: 33px;
    width: 380px;
    height: 70px;
    background: #f8f8f8;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    line-height: 70px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
    cursor: pointer;

    span {
      color: #ff4027;
    }
  }
}</style>
